import { Injectable } from '@angular/core';
import {LoggerService} from "@core/services/logger.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {IRecaptchaVerificationResponse} from "@interfaces/recaptcha-verification-response";
import {tap} from "rxjs/operators";
import {ConstantsService} from "@core/services/constants.service";
import {AppConfigService} from "@core/services/app-config.service";

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(private readonly logger: LoggerService,
              private readonly http: HttpClient,
              private appConfigService: AppConfigService,
              private readonly constants: ConstantsService) { }

  apiUrl: string = this.appConfigService.config.webApi.url + "/recaptcha";

  // async checkConfigSettings() {
  //   this.logger.debug('unifiedCheckoutService.checkConfigSettings');
  //   // Check if settings has already been set
  //   if (AppConfigService.settings == null) {
  //     // If not, load it now
  //     AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
  //     this.logger.info('unifiedCheckoutService.checkConfigSettings | Config settings loaded: ', AppConfigService.settings);
  //   }
  //   // Use data to update local variables
  //   this.apiUrl = AppConfigService.settings.webApi.url + "/recaptcha";
  //   this.logger.debug(`apiUrl: ${this.apiUrl}`);
  //
  // }

  async verifyRecaptcha(token: string): Promise<IRecaptchaVerificationResponse> {
    this.logger.debug("recaptcha.service.verifyRecaptcha");
    this.logger.trace(`recaptcha.service.verifyRecaptcha | token: ${token}`);

    const url = `${this.apiUrl}/verify`;

    const options = { params: new HttpParams().set('token', token) };

    this.logger.trace(`recaptcha.service.verifyRecaptcha | url: ${url}`);

    return this.http.post<IRecaptchaVerificationResponse>(url, null, options)
      .pipe(tap(data => {this.logger.trace('recaptcha.service.verifyRecaptcha | result: ', data)}))
      .toPromise();

  }

}
